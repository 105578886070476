const requisitionBrowserView = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'RequisitionRequestItemId',
        type: 'check'
      },
      {
        label: 'Categoría',
        type: String,
        key: 'Category',
        sortable: true
      },
      {
        label: 'Modelo',
        type: String,
        key: 'ModelNumber',
        sortable: true
      },
      {
        label: 'Descripción',
        type: String,
        key: 'Description',
        sortable: true
      },
      {
        label: 'Fecha necesaria',
        key: 'RequestedDueDate',
        sortable: true
      },
      {
        label: 'Código de costo',
        key: 'CostCodeDescription',
        sortable: true
      },
      {
        label: 'Solicitar cantidad',
        key: 'RequestedQty',
        sortable: true
      },
      {
        label: 'Cantidad indecisa',
        key: 'UndecidedQty',
        sortable: true
      },
      {
        label: 'Cantidad accionada',
        key: 'ActionedQty',
        sortable: true
      },
      {
        label: 'Seleccionar cantidad',
        key: 'PickQty',
        sortable: true
      },
      {
        label: 'Cantidad de compra',
        key: 'PurchaseQty',
        sortable: true
      },
      {
        label: 'Cantidad rechazada',
        key: 'RejectedQty',
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'RequisitionRequestHeaderId',
        type: 'check'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Categoría',
            type: String,
            key: 'Category',
            sortable: true
          },
          {
            label: 'Modelo',
            type: String,
            key: 'ModelNumber',
            sortable: true
          },
          {
            label: 'Descripción',
            type: String,
            key: 'Description',
            sortable: true
          },
          {
            label: 'Fecha necesaria',
            key: 'RequestedDueDate',
            sortable: true
          },
          {
            label: 'Código de costo',
            key: 'CostCodeDescription',
            sortable: true
          },
          {
            label: 'Modelo cantidad',
            key: 'SumOfModelQty',
            sortable: true,
            flag: 'showRequisitionsV2'
          },
          {
            label: 'Modelo Cantidad disponible',
            key: 'SumOfModelAvailableQty',
            sortable: true,
            flag: 'showRequisitionsV2'
          },
          {
            label: 'Solicitar cantidad',
            key: 'RequestedQty',
            sortable: true
          },
          {
            label: 'Cantidad indecisa',
            key: 'UndecidedQty',
            sortable: true
          },
          {
            label: 'Cantidad accionada',
            key: 'ActionedQty',
            sortable: true
          },
          {
            label: 'Seleccionar cantidad',
            key: 'PickQty',
            sortable: true
          },
          {
            label: 'Cantidad de compra',
            key: 'PurchaseQty',
            sortable: true
          },
          {
            label: 'Cantidad rechazada',
            key: 'RejectedQty',
            sortable: true
          }
        ]
      }
    ]
  },
  tableHeaderFlag: {
    desktop: [
      {
        label: '',
        key: 'RequisitionRequestItemId',
        type: 'check'
      },
      {
        label: 'Categoría',
        type: String,
        key: 'Category',
        sortable: true
      },
      {
        label: 'Model',
        type: String,
        key: 'Número de modelo',
        sortable: true
      },
      {
        label: 'Descripción',
        type: String,
        key: 'Description',
        sortable: true
      },
      {
        label: 'Fecha necesaria',
        key: 'RequestedDueDate',
        sortable: true
      },
      {
        label: 'Código de costo',
        key: 'CostCodeDescription',
        sortable: true
      },
      {
        label: 'Modelo cantidad',
        key: 'SumOfModelQty',
        sortable: true,
        flag: 'showRequisitionsV2'
      },
      {
        label: 'Modelo Cantidad disponible',
        key: 'SumOfModelAvailableQty',
        sortable: true,
        flag: 'showRequisitionsV2'
      },
      {
        label: 'Solicitar cantidad',
        key: 'RequestedQty',
        sortable: true
      },
      {
        label: 'Cantidad indecisa',
        key: 'UndecidedQty',
        sortable: true
      },
      {
        label: 'Cantidad accionada',
        key: 'ActionedQty',
        sortable: true
      },
      {
        label: 'Seleccionar cantidad',
        key: 'PickQty',
        sortable: true
      },
      {
        label: 'Cantidad de compra',
        key: 'PurchaseQty',
        sortable: true
      },
      {
        label: 'Cantidad rechazada',
        key: 'RejectedQty',
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'RequisitionRequestHeaderId',
        type: 'check'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Categoría',
            type: String,
            key: 'Category',
            sortable: true
          },
          {
            label: 'Modelo',
            type: String,
            key: 'ModelNumber',
            sortable: true
          },
          {
            label: 'Descripción',
            type: String,
            key: 'Description',
            sortable: true
          },
          {
            label: 'Fecha necesaria',
            key: 'RequestedDueDate',
            sortable: true
          },
          {
            label: 'Código de costo',
            key: 'CostCodeDescription',
            sortable: true
          },
          {
            label: 'Modelo cantidad',
            key: 'SumOfModelQty',
            sortable: true,
            flag: 'showRequisitionsV2'
          },
          {
            label: 'Modelo Cantidad disponible',
            key: 'SumOfModelAvailableQty',
            sortable: true,
            flag: 'showRequisitionsV2'
          },
          {
            label: 'Solicitar cantidad',
            key: 'RequestedQty',
            sortable: true
          },
          {
            label: 'Cantidad indecisa',
            key: 'UndecidedQty',
            sortable: true
          },
          {
            label: 'Cantidad accionada',
            key: 'ActionedQty',
            sortable: true
          },
          {
            label: 'Seleccionar cantidad',
            key: 'PickQty',
            sortable: true
          },
          {
            label: 'Cantidad de compra',
            key: 'PurchaseQty',
            sortable: true
          },
          {
            label: 'Cantidad rechazada',
            key: 'RejectedQty',
            sortable: true
          }
        ]
      }
    ]
  },
  reqAction: {
    title: 'Acción de solicitud',
    vendorTitle: 'Seleccione Proveedor para orden de compra',
    warningMessage: 'Artículo(s) Cantidad de acción ingresada mayor que Cantidad indecisa.',
    warningZeroValue: 'El valor de la cantidad de acción es cero',
    warningRejectedGreaterValue: 'Artículo(s) Cantidad de acción ingresada mayor que Cantidad rechazada.',
    tableHeader: {
      desktop: [
        {
          label: 'Categoría',
          type: String,
          key: 'Category',
          sortable: true
        },
        {
          label: 'Model',
          type: String,
          key: 'Número de modelo',
          sortable: true
        },
        {
          label: 'Descripción',
          type: String,
          key: 'Description',
          sortable: true
        },
        {
          label: 'Fecha necesaria',
          key: 'RequestedDueDate',
          sortable: true
        },
        {
          label: 'Código de costo',
          key: 'CostCodeDescription',
          sortable: true
        },
        {
          label: 'Cantidad de acción',
          key: 'ActionQty',
          type: Number,
          sortable: false
        },
        {
          label: 'Solicitar cantidad',
          key: 'RequestedQty',
          sortable: true
        },
        {
          label: 'Cantidad indecisa',
          key: 'UndecidedQty',
          sortable: true
        },
        {
          label: 'Cantidad accionada',
          key: 'ActionedQty',
          sortable: true
        },
        {
          label: 'Seleccionar cantidad',
          key: 'PickQty',
          sortable: true
        },
        {
          label: 'Cantidad de compra',
          key: 'PurchaseQty',
          sortable: true
        },
        {
          label: 'Cantidad rechazada',
          key: 'RejectedQty',
          sortable: true
        }
      ],
      mobile: [
        {
          label: 'Info',
          data: [
            {
              label: 'Categoría',
              type: String,
              key: 'Category',
              sortable: true
            },
            {
              label: 'Model',
              type: String,
              key: 'Número de modelo',
              sortable: true
            },
            {
              label: 'Descripción',
              type: String,
              key: 'Description',
              sortable: true
            },
            {
              label: 'Fecha necesaria',
              key: 'RequestedDueDate',
              sortable: true
            },
            {
              label: 'Código de costo',
              key: 'CostCodeDescription',
              sortable: true
            },
            {
              label: 'Cantidad de acción',
              key: 'ActionQty',
              type: Number,
              sortable: false
            },
            {
              label: 'Solicitar cantidad',
              key: 'RequestedQty',
              sortable: true
            },
            {
              label: 'Cantidad indecisa',
              key: 'UndecidedQty',
              sortable: true
            },
            {
              label: 'Cantidad accionada',
              key: 'ActionedQty',
              sortable: true
            },
            {
              label: 'Seleccionar cantidad',
              key: 'PickQty',
              sortable: true
            },
            {
              label: 'Cantidad de compra',
              key: 'PurchaseQty',
              sortable: true
            },
            {
              label: 'Cantidad rechazada',
              key: 'RejectedQty',
              sortable: true
            }
          ]
        }
      ]
    },
    requisitionActions: [
      {
        title: 'Orden de compra',
        actionName: 'onPurchasOrder',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'Recoger boleto',
        actionName: 'onPickTicket',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'Rechazar',
        actionName: 'onReject',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'Deshacer Rechazar',
        actionName: 'onUndoReject',
        requiresSelection: true,
        allowsMultiSelect: true
      }
    ]
  },
  browserLineActions: {
    lineActions: [
      {
        title: 'Orden de Compra',
        actionName: 'onPurchasOrder',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'Recoger Ticket',
        actionName: 'onPickTicket',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'Rechazar',
        actionName: 'onReject',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'Deshacer Rechazo',
        actionName: 'onUndoReject',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'Eliminar',
        actionName: 'onDelete',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'Historial',
        actionName: 'onHistory',
        requiresSelection: true,
        allowsMultiSelect: true
      }
    ]
  },
  reqHistory: {
    title: 'Historial de acción',
    tableHeader: {
      desktop: [
        {
          label: 'Fecha/tiempo',
          type: Date,
          key: 'ActionTakenOn',
          sortable: true
        },
        {
          label: 'Número',
          type: String,
          key: 'Number',
          sortable: true
        },
        {
          label: 'Tipo',
          type: String,
          key: 'ActionType',
          sortable: true
        },
        {
          label: 'Modelo',
          key: 'Model',
          sortable: true
        },
        {
          label: 'Descripción',
          key: 'Description',
          sortable: true
        },
        {
          label: 'Cantidad',
          key: 'Quantity',
          sortable: true
        }
      ],
      mobile: [
        {
          label: 'Info',
          data: [
            {
              label: 'Fecha/tiempo',
              type: Date,
              key: 'ActionTakenOn',
              sortable: true
            },
            {
              label: 'Número',
              type: String,
              key: 'Number',
              sortable: true
            },
            {
              label: 'Tipo',
              type: String,
              key: 'ActionType',
              sortable: true
            },
            {
              label: 'Modelo',
              key: 'Model',
              sortable: true
            },
            {
              label: 'Descripción',
              key: 'Description',
              sortable: true
            },
            {
              label: 'Cantidad',
              key: 'Quantity',
              sortable: true
            }
          ]
        }
      ]
    }
  }

}
export default requisitionBrowserView

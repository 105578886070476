export default {
  state: () => ({
    id: 1,
    userName: '',
    firstName: '',
    lastName: '',
    permissions: [],
    entitlements: [],
    allEntitlements: [],
    isLoggedIn: false,
    jobSiteUser: 0,
    showCloudWorkInProgress: false,
    ownerIsRootForQuantityTools: false,
    fLogin: false,
    isAllowUserNumbers: false,
    requiresReload: false,
    isSandboxUser: false,
    assignedToId: '',
    assignedStockpointChanged: false,
    isSuperUser: false,
    showCloudReservations: false,
    showCloudScheduleBackOffice: false,
    showCloudScheduleWarehouse: false,
    ViewGoogleMap: false,
    loginCluster: '',
    ReportCustomDashboard: false,
    showTasks: false,
    ShowUsersInAdmin: false,
    showFuelCards: false,
    showSafetyMenu: false,
    safetyAccountId: null,
    ShowDocumentCentral: false,
    showScheduling: false,
    isEHSOnly: false,
    showInsights: false,
    showNotification: false,
    showPlatformNavigation: false,
    showRequisitionsV2: false,
    hasPredictLossModel: false,
    ShowSafetyPlan: false,
    ShowEnhancedWorkflows: false,
    ShowPlatformRolesAndPermissions: false,
    ItemBrowserMaxRowsCount: null,
    WorkOrderMaxRowsCount: null,
    ShowEquipmentNav: false,
    ShowWorkflowApprovals: false,
    ShowServiceRequestNotification: false,
    GpsSupport: false,
    CustomReportingEnhancements: false,
    ShowPurchaseOrderItemBrowser: false,
    ShowIntercom: false,
    ShowRequisitionsImprovements: false,
    ShowWFM: false,
    ShowWorkForceNav: true,
    ShowPickedItemState: false,
    EHSCustomReports: false,
    ShowJobStock: false,
    SupportRedesign: false,
    ShowTasksWorkflowsFollowOn: false,
    channelName: '',
    orgSpecificFlagValues: [],
    showCollaborateExpansion: false,
    closeIntercom: false,
    showInvoiceComments: false,
    ShowMapUpdates: false,
    AlignOpsRebrand: false
  }),
  mutations: {
    setUserPermissions (state, permissions) {
      state.permissions = [].concat(permissions)
    },
    setEntitlements (state, entitlements) {
      state.entitlements = [].concat(entitlements)
    },
    setJobSiteUser (state, flag) {
      state.jobSiteUser = flag
    },
    setShowCloudWorkInProgress (state, flag) {
      state.showCloudWorkInProgress = flag
    },
    setOwnerIsRootForQuantityTools (state, flag) {
      state.ownerIsRootForQuantityTools = flag
    },
    setShowCloudReservations (state, flag) {
      state.showCloudReservations = flag
    },
    setShowCloudScheduleWarehouse (state, flag) {
      state.showCloudScheduleWarehouse = flag
    },
    setShowCloudScheduleBackOffice (state, flag) {
      state.showCloudScheduleBackOffice = flag
    },
    setLoggedIn (state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn
    },
    setFLogin (state, fLogin) {
      state.fLogin = fLogin
    },
    setUser (state, { fname, lname, uname }) {
      state.firstName = fname
      state.lastName = lname
      state.userName = uname
    },
    setAllowUserNumbers (state, flag) {
      state.isAllowUserNumbers = flag
    },
    setRequiresReload (state, value) {
      state.requiresReload = value
    },
    setIsSandboxUser (state, value) {
      state.isSandboxUser = value
    },
    setAssignedToId (state, value) {
      state.assignedToId = value
    },
    setAssignedStockpointChanged (state, value) {
      state.assignedStockpointChanged = value
    },
    setIsSuperUser (state, value) {
      state.isSuperUser = value
    },
    setViewGoogleMap (state, value) {
      state.ViewGoogleMap = value
    },
    setShowReportCustomDashboard (state, value) {
      state.ReportCustomDashboard = value
    },
    setLoginCluster (state, value) {
      state.loginCluster = value
    },
    setShowTasks (state, value) {
      state.showTasks = value
    },
    setShowUsersInAdmin (state, value) {
      state.ShowUsersInAdmin = value
    },
    setShowFuelCards (state, value) {
      state.showFuelCards = value
    },
    setShowSafetyMenu (state, value) {
      state.showSafetyMenu = value
    },
    setShowCollaborate (state, value) {
      state.showCollaborate = value
    },
    setSafetyAccountId (state, value) {
      state.safetyAccountId = value
    },
    setShowDocumentCentral (state, value) {
      state.ShowDocumentCentral = value
    },
    setShowScheduling (state, value) {
      state.showScheduling = value
    },
    setIsEHSOnly (state, value) {
      state.isEHSOnly = value
    },
    setShowInsights (state, value) {
      state.showInsights = value
    },
    setShowNotification (state, value) {
      state.showNotification = value
    },
    setShowPlatformNavigation (state, value) {
      state.showPlatformNavigation = value
    },
    setShowRequisitionsV2 (state, value) {
      state.showRequisitionsV2 = value
    },
    setHasPredictLossModel (state, value) {
      state.hasPredictLossModel = value
    },
    setShowSafetyPlan (state, value) {
      state.ShowSafetyPlan = value
    },
    SetShowEnhancedWorkflows (state, value) {
      state.ShowEnhancedWorkflows = value
    },
    SetShowWorkflowApprovals (state, value) {
      state.ShowWorkflowApprovals = value
    },
    setShowPlatformRolesAndPermissions (state, value) {
      state.ShowPlatformRolesAndPermissions = value
    },
    setItemBrowserMaxRowsCount (state, value) {
      state.ItemBrowserMaxRowsCount = value
    },
    setWorkOrderMaxRowsCount (state, value) {
      state.WorkOrderMaxRowsCount = value
    },
    setShowEquipmentNav (state, value) {
      state.ShowEquipmentNav = value
    },
    setShowServiceRequestNotification (state, value) {
      state.ShowServiceRequestNotification = value
    },
    setGpsSupport (state, value) {
      state.GpsSupport = value
    },
    setCustomReportingEnhancements (state, value) {
      state.CustomReportingEnhancements = value
    },
    setShowRequisitionBrowser (state, value) {
      state.ShowRequisitionBrowser = value
    },
    setShowTasksWorkflowsFollowOn (state, value) {
      state.ShowTasksWorkflowsFollowOn = value
    },
    setShowPurchaseOrderItemBrowser (state, value) {
      state.ShowPurchaseOrderItemBrowser = value
    },
    setShowIntercom (state, value) {
      state.ShowIntercom = value
    },
    setShowRequisitionsImprovements (state, value) {
      state.ShowRequisitionsImprovements = value
    },
    setShowWorkForceNav (state, value) {
      state.ShowWorkForceNav = value
    },
    setShowWFM (state, value) {
      state.ShowWFM = value
    },
    setShowPickedItemState (state, value) {
      state.ShowPickedItemState = value
    },
    setEHSCustomReports (state, value) {
      state.EHSCustomReports = value
    },
    setShowJobStock (state, value) {
      state.ShowJobStock = value
    },
    SetSupportRedesign (state, value) {
      state.SupportRedesign = value
    },
    setChannelName (state, value) {
      state.channelName = value
    },
    setOrgSpecificFlagValues (state, value) {
      state.orgSpecificFlagValues = value
    },
    SetShowCollaborateExpansion (state, value) {
      state.showCollaborateExpansion = value
    },
    setCloseIntercom (state, value) {
      state.closeIntercom = value
    },
    setShowInvoiceComments (state, value) {
      state.showInvoiceComments = value
    },
    setShowMapUpdates (state, value) {
      state.ShowMapUpdates = value
    },
    setAlignOpsRebrand (state, value) {
      state.AlignOpsRebrand = value
    }
  },
  getters: {
    fullName (state) {
      return state.firstName + ' ' + state.lastName
    },
    firstName (state) {
      return state.firstName
    },
    lastName (state) {
      return state.lastName
    },
    isLoggedIn (state) {
      return state.isLoggedIn
    },
    isFLogin (state) {
      return state.fLogin
    },
    userPermission (state) {
      return state.permissions
    },
    userEntitlement (state) {
      return state.entitlements.filter(x => x.Entitled).map(x => x.Key)
    },
    allEntitlements (state) {
      return state.entitlements
    },
    jobSiteUser (state) {
      return state.jobSiteUser
    },
    showCloudWorkInProgress (state) {
      return state.showCloudWorkInProgress
    },
    ownerIsRootForQuantityTools (state) {
      return state.ownerIsRootForQuantityTools
    },
    showCloudReservations (state) {
      return state.showCloudReservations
    },
    showCloudScheduleWarehouse (state) {
      return state.showCloudScheduleWarehouse
    },
    showCloudScheduleBackOffice (state) {
      return state.showCloudScheduleBackOffice
    },
    getAllowUserNumbers (state) {
      return state.isAllowUserNumbers
    },
    requiresReload (state) {
      return state.requiresReload
    },
    isSandboxUser (state) {
      return state.isSandboxUser
    },
    assignedToId (state) {
      return state.assignedToId
    },
    assignedStockpointChanged (state) {
      return state.assignedStockpointChanged
    },
    isSuperUser (state) {
      return state.isSuperUser
    },
    userName (state) {
      return state.userName
    },
    isViewGoogleMap (state) {
      return state.ViewGoogleMap
    },
    loginCluster (state) {
      return state.loginCluster
    },
    isShowReportCustomDashboard (state) {
      return state.ReportCustomDashboard
    },
    isShowTasks (state) {
      return state.showTasks
    },
    isShowUsersInAdmin (state) {
      return state.ShowUsersInAdmin
    },
    isShowFuelCards (state) {
      return state.showFuelCards
    },
    isShowSafetyMenu (state) {
      return state.showSafetyMenu
    },
    isShowCollaborate (state) {
      return state.showCollaborate
    },
    safetyAccountId (state) {
      return state.safetyAccountId
    },
    isShowDocumentCentral (state) {
      return state.ShowDocumentCentral
    },
    isShowScheduling (state) {
      return state.showScheduling
    },
    isEHSOnly (state) {
      return state.isEHSOnly
    },
    isShowInsights (state) {
      return state.showInsights
    },
    isShowNotification (state) {
      return state.showNotification
    },
    showPlatformNavigation (state) {
      return state.showPlatformNavigation
    },
    showRequisitionsV2 (state) {
      return state.showRequisitionsV2
    },
    hasPredictLossModel (state) {
      return state.hasPredictLossModel
    },
    isShowSafetyPlan (state) {
      return state.ShowSafetyPlan
    },
    isShowEnhancedWorkflows (state) {
      return state.ShowEnhancedWorkflows
    },
    isShowWorkflowApprovals (state) {
      return state.ShowWorkflowApprovals
    },
    showPlatformRolesAndPermissions (state) {
      return state.ShowPlatformRolesAndPermissions
    },
    itemBrowserMaxRowsCount (state) {
      return state.ItemBrowserMaxRowsCount
    },
    workOrderMaxRowsCount (state) {
      return state.WorkOrderMaxRowsCount
    },
    isShowEquipmentNav (state) {
      return state.ShowEquipmentNav
    },
    isShowServiceRequestNotification (state) {
      return state.ShowServiceRequestNotification
    },
    isGpsSupport (state) {
      return state.GpsSupport
    },
    isCustomReportingEnhancements (state) {
      return state.CustomReportingEnhancements
    },
    isShowRequisitionBrowser (state) {
      return state.ShowRequisitionBrowser
    },
    isShowTasksWorkflowsFollowOn (state) {
      return state.ShowTasksWorkflowsFollowOn
    },
    isShowPurchaseOrderItemBrowser (state) {
      return state.ShowPurchaseOrderItemBrowser
    },
    isShowIntercom (state) {
      return state.ShowIntercom
    },
    isShowRequisitionsImprovements (state) {
      return state.ShowRequisitionsImprovements
    },
    isShowWorkForceNav (state) {
      return state.ShowWorkForceNav
    },
    isShowWFM (state) {
      return state.ShowWFM
    },
    isShowPickedItemState (state) {
      return state.ShowPickedItemState
    },
    isEHSCustomReports (state) {
      return state.EHSCustomReports
    },
    isShowJobStock (state) {
      return state.ShowJobStock
    },
    isSupportRedesign (state) {
      return state.SupportRedesign
    },
    channelName (state) {
      return state.channelName
    },
    orgSpecificFlagValues (state) {
      return state.orgSpecificFlagValues
    },
    showCollaborateExpansion (state) {
      return state.showCollaborateExpansion
    },
    isCloseIntercom (state) {
      return state.closeIntercom
    },
    showInvoiceComments (state) {
      return state.showInvoiceComments
    },
    isShowMapUpdates (state) {
      return state.ShowMapUpdates
    },
    isAlignOpsRebrand (state) {
      return state.AlignOpsRebrand
    }
  }
}

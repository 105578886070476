const requisitionBrowserView = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'RequisitionRequestItemId',
        type: 'check'
      },
      {
        label: 'Category',
        type: String,
        key: 'Category',
        sortable: true
      },
      {
        label: 'Model',
        type: String,
        key: 'ModelNumber',
        sortable: true
      },
      {
        label: 'Description',
        type: String,
        key: 'Description',
        sortable: true
      },
      {
        label: 'Date Needed',
        key: 'RequestedDueDate',
        sortable: true
      },
      {
        label: 'Cost Code',
        key: 'CostCodeDescription',
        sortable: true
      },
      {
        label: 'Request Qty',
        key: 'RequestedQty',
        sortable: true
      },
      {
        label: 'Undecided Qty',
        key: 'UndecidedQty',
        sortable: true
      },
      {
        label: 'Actioned Qty',
        key: 'ActionedQty',
        sortable: true
      },
      {
        label: 'Pick Qty',
        key: 'PickQty',
        sortable: true
      },
      {
        label: 'Purchase Qty',
        key: 'PurchaseQty',
        sortable: true
      },
      {
        label: 'Rejected Qty',
        key: 'RejectedQty',
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'RequisitionRequestHeaderId',
        type: 'check'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Category',
            type: String,
            key: 'Category',
            sortable: true
          },
          {
            label: 'Model',
            type: String,
            key: 'ModelNumber',
            sortable: true
          },
          {
            label: 'Description',
            type: String,
            key: 'Description',
            sortable: true
          },
          {
            label: 'Date Needed',
            key: 'RequestedDueDate',
            sortable: true
          },
          {
            label: 'Cost Code',
            key: 'CostCodeDescription',
            sortable: true
          },
          {
            label: 'Request Qty',
            key: 'RequestedQty',
            sortable: true
          },
          {
            label: 'Undecided Qty',
            key: 'UndecidedQty',
            sortable: true
          },
          {
            label: 'Actioned Qty',
            key: 'ActionedQty',
            sortable: true
          },
          {
            label: 'Pick Qty',
            key: 'PickQty',
            sortable: true
          },
          {
            label: 'Purchase Qty',
            key: 'PurchaseQty',
            sortable: true
          },
          {
            label: 'Rejected Qty',
            key: 'RejectedQty',
            sortable: true
          }
        ]
      }
    ]
  },
  tableHeaderFlag: {
    desktop: [
      {
        label: '',
        key: 'RequisitionRequestItemId',
        type: 'check'
      },
      {
        label: 'Category',
        type: String,
        key: 'Category',
        sortable: true
      },
      {
        label: 'Model',
        type: String,
        key: 'ModelNumber',
        sortable: true
      },
      {
        label: 'Description',
        type: String,
        key: 'Description',
        sortable: true
      },
      {
        label: 'Date Needed',
        key: 'RequestedDueDate',
        sortable: true
      },
      {
        label: 'Cost Code',
        key: 'CostCodeDescription',
        sortable: true
      },
      {
        label: 'Model Qty',
        key: 'SumOfModelQty',
        sortable: true,
        flag: 'showRequisitionsV2'
      },
      {
        label: 'Model Available Qty',
        key: 'SumOfModelAvailableQty',
        sortable: true,
        flag: 'showRequisitionsV2'
      },
      {
        label: 'Request Qty',
        key: 'RequestedQty',
        sortable: true
      },
      {
        label: 'Undecided Qty',
        key: 'UndecidedQty',
        sortable: true
      },
      {
        label: 'Actioned Qty',
        key: 'ActionedQty',
        sortable: true
      },
      {
        label: 'Pick Qty',
        key: 'PickQty',
        sortable: true
      },
      {
        label: 'Purchase Qty',
        key: 'PurchaseQty',
        sortable: true
      },
      {
        label: 'Rejected Qty',
        key: 'RejectedQty',
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'RequisitionRequestHeaderId',
        type: 'check'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Category',
            type: String,
            key: 'Category',
            sortable: true
          },
          {
            label: 'Model',
            type: String,
            key: 'ModelNumber',
            sortable: true
          },
          {
            label: 'Description',
            type: String,
            key: 'Description',
            sortable: true
          },
          {
            label: 'Date Needed',
            key: 'RequestedDueDate',
            sortable: true
          },
          {
            label: 'Cost Code',
            key: 'CostCodeDescription',
            sortable: true
          },
          {
            label: 'Model Qty',
            key: 'SumOfModelQty',
            sortable: true,
            flag: 'ShowRequisitionsV2'
          },
          {
            label: 'Model Available Qty',
            key: 'SumOfModelAvailableQty',
            sortable: true,
            flag: 'ShowRequisitionsV2'
          },
          {
            label: 'Request Qty',
            key: 'RequestedQty',
            sortable: true
          },
          {
            label: 'Undecided Qty',
            key: 'UndecidedQty',
            sortable: true
          },
          {
            label: 'Actioned Qty',
            key: 'ActionedQty',
            sortable: true
          },
          {
            label: 'Pick Qty',
            key: 'PickQty',
            sortable: true
          },
          {
            label: 'Purchase Qty',
            key: 'PurchaseQty',
            sortable: true
          },
          {
            label: 'Rejected Qty',
            key: 'RejectedQty',
            sortable: true
          }
        ]
      }
    ]
  },
  reqAction: {
    title: 'Requisition Action',
    vendorTitle: 'Please select Vendor for Purchase Order',
    warningGreaterValue: 'Item(s) Action Qty entered greater than Undecided Qty.',
    warningZeroValue: 'Action Qty value is Zero',
    warningRejectedGreaterValue: 'Item(s) Action Qty entered greater than Rejected Qty.',
    tableHeader: {
      desktop: [
        {
          label: 'Category',
          type: String,
          key: 'Category',
          sortable: true
        },
        {
          label: 'Model',
          type: String,
          key: 'ModelNumber',
          sortable: true
        },
        {
          label: 'Description',
          type: String,
          key: 'Description',
          sortable: true
        },
        {
          label: 'Date Needed',
          key: 'RequestedDueDate',
          sortable: true
        },
        {
          label: 'Cost Code',
          key: 'CostCodeDescription',
          sortable: true
        },
        {
          label: 'Action Qty',
          key: 'ActionQty',
          type: Number,
          sortable: false
        },
        {
          label: 'Request Qty',
          key: 'RequestedQty',
          sortable: true
        },
        {
          label: 'Undecided Qty',
          key: 'UndecidedQty',
          sortable: true
        },
        {
          label: 'Actioned Qty',
          key: 'ActionedQty',
          sortable: true
        },
        {
          label: 'Pick Qty',
          key: 'PickQty',
          sortable: true
        },
        {
          label: 'Purchase Qty',
          key: 'PurchaseQty',
          sortable: true
        },
        {
          label: 'Rejected Qty',
          key: 'RejectedQty',
          sortable: true
        }
      ],
      mobile: [
        {
          label: 'Info',
          data: [
            {
              label: 'Category',
              type: String,
              key: 'Category',
              sortable: true
            },
            {
              label: 'Model',
              type: String,
              key: 'ModelNumber',
              sortable: true
            },
            {
              label: 'Description',
              type: String,
              key: 'Description',
              sortable: true
            },
            {
              label: 'Date Needed',
              key: 'RequestedDueDate',
              sortable: true
            },
            {
              label: 'Cost Code',
              key: 'CostCodeDescription',
              sortable: true
            },
            {
              label: 'Action Qty',
              key: 'ActionQty',
              type: Number,
              sortable: false
            },
            {
              label: 'Request Qty',
              key: 'RequestedQty',
              sortable: true
            },
            {
              label: 'Undecided Qty',
              key: 'UndecidedQty',
              sortable: true
            },
            {
              label: 'Actioned Qty',
              key: 'ActionedQty',
              sortable: true
            },
            {
              label: 'Pick Qty',
              key: 'PickQty',
              sortable: true
            },
            {
              label: 'Purchase Qty',
              key: 'PurchaseQty',
              sortable: true
            },
            {
              label: 'Rejected Qty',
              key: 'RejectedQty',
              sortable: true
            }
          ]
        }
      ]
    },
    requisitionActions: [
      {
        title: 'Purchase Order',
        actionName: 'onPurchasOrder',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'Pick Ticket',
        actionName: 'onPickTicket',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'Reject',
        actionName: 'onReject',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'Undo Reject',
        actionName: 'onUndoReject',
        requiresSelection: true,
        allowsMultiSelect: true
      }
    ]
  },
  browserLineActions: {
    lineActions: [
      {
        title: 'Purchase Order',
        actionName: 'onPurchasOrder',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'Pick Ticket',
        actionName: 'onPickTicket',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'Reject',
        actionName: 'onReject',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'Undo Reject',
        actionName: 'onUndoReject',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'Delete',
        actionName: 'onDelete',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'History',
        actionName: 'onHistory',
        requiresSelection: true,
        allowsMultiSelect: true
      }
    ]
  },
  reqHistory: {
    title: 'Action History',
    tableHeader: {
      desktop: [
        {
          label: 'Date/Time',
          type: Date,
          key: 'ActionTakenOn',
          sortable: true
        },
        {
          label: 'No',
          type: String,
          key: 'Number',
          sortable: true
        },
        {
          label: 'Type',
          type: String,
          key: 'ActionType',
          sortable: true
        },
        {
          label: 'Model',
          key: 'Model',
          sortable: true
        },
        {
          label: 'Description',
          key: 'Description',
          sortable: true
        },
        {
          label: 'Qty',
          key: 'Quantity',
          sortable: true
        }
      ],
      mobile: [
        {
          label: 'Info',
          data: [
            {
              label: 'Date/time',
              type: String,
              key: 'actionTakenOn',
              sortable: true
            },
            {
              label: 'No',
              type: Number,
              key: 'Number',
              sortable: true
            },
            {
              label: 'Type',
              type: String,
              key: 'ActionType',
              sortable: true
            },
            {
              label: 'Model',
              key: 'RequestedDueDate',
              sortable: true
            },
            {
              label: 'Description',
              key: 'RequestedQty',
              sortable: true
            },
            {
              label: 'Qty',
              key: 'Quantity',
              sortable: true
            }
          ]
        }
      ]
    }
  }
}
export default requisitionBrowserView

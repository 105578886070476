const collaborate = {
  title: 'Colaborar',
  actionName: {
    0: {
      name: 'Editar'
    },
    1: {
      name: 'Borrar'
    },
    3: {
      name: 'Ahorrar'
    },
    4: {
      name: 'Cancelar'
    },
    btnCollaboration: 'Publicar colaboración',
    placeHolder: 'Escribe aquí tu colaboración...',
    toolTip: 'El ticket de selección debe guardarse primero en Collaborate',
    deleteConfirm: '¿Seguro que quieres eliminar este comentario?',
    deleteTitle: 'Eliminar comentario',
    deleteBtnText: 'Borrar',
    cancelBtnText: 'Cancelar'
  }
}
export default collaborate

import CONST from '../../helpers/const'

const catalog = {
  title: 'Catalogo de producto',
  detailTitle: 'Detalles del producto',
  companyName: '{compañia} catalogo',
  placeholder: 'Ingrese un modelo, descripción o fabricante',
  suggestionMsg: 'Lo sentimos, no hay opciones que coincidan',
  switcher: 'Solo elementos rastreados',
  viewModel: 'Ver este modelo.',
  allItem: 'Todos los artículos',
  equipment: 'Equipo',
  allCategories: 'Todas Las Categorías',
  templateCategories: 'Categorías de plantillas',
  allProducts: 'Todos los productos',
  tools: 'Herramientas y Equipamiento',
  materials: 'Materiales y consumibles',
  allBrands: 'Todas Los Fabricantes',
  template: 'Plantilla',
  allTemplates: 'Todas las plantillas',
  recentView: {
    header: 'visto recientemente',
    noMsg: 'No hay elementos vistos recientemente'
  },
  recentAdded: {
    header: 'Recientemente agregados',
    noMsg: 'No hay elementos agregados recientemente'
  },
  category: 'Categorías',
  brand: 'Fabricantes',
  seeMore: 'Ver más',
  createItem: 'Crea tu propio artículo nuevo',
  addCategory: '... Añadir categoría',
  addManufacturer: '... Añadir fabricante',
  searchItem: 'Buscar un artículo o',
  dontSee: '¿No ves lo que estás buscando?',
  itemCnt: 'Ningún artículo rastreado | 1 articulo rastreado | {count} artículos rastreados',
  itemStocked: 'Artículo en existencia',
  createModal: {
    title: 'Crear nuevo modelo',
    duplicateMsg: 'Ya existe un modelo con el nombre {modelName} en su catálogo.',
    dewaltMsg: 'NO SE PUEDE AÑADIR EL ARTÍCULO. Este artículo utiliza un número de serie único digital DeWALT que ya está en uso. Consulte {duplicatedModelNumber}, número de artículo {duplicatedItemNumber}.',
    dewaltUSN: 'El modelo y el número de serie se obtuvieron de DeWALT USN.',
    tipMsg: 'La cantidad del artículo debe ser cero para deshacer el stock.',
    itemType: {
      label: 'Tipo de artículo',
      options: {
        0: 'Herramienta',
        1: 'Materiales y consumibles',
        2: 'Equipos'
      }
    },
    trackingMethod: {
      label: 'Método de seguimiento',
      options: {
        0: 'Identificación única',
        1: 'Cantidad'
      }
    },
    modalLabels: {
      0: 'Fabricante',
      1: 'Marca',
      2: 'Categoría',
      3: 'Modelo',
      4: 'Numero de catalogo',
      5: 'UPC',
      6: 'Año',
      7: 'Descripción',
      8: 'Número de serie',
      9: 'Número de artículo',
      10: 'Código de barras',
      11: 'Fecha de compra',
      12: 'Costo de compra'
    }
  },
  catalogDetail: {
    productInfo: {
      title: 'Información del Producto',
      labels: {
        0: 'Fabricante',
        1: 'Modelo',
        2: 'Numero de catalogo',
        3: 'UPC/ASIN',
        4: 'Año',
        5: 'Descripción del fabricante',
        6: 'Características del fabricante'
      }
    },
    trackingInfo: {
      title: 'Información de seguimiento',
      labels: {
        0: 'Categoría',
        1: 'Descripción genérica',
        2: 'Tipo de artículo',
        3: 'Método de seguimiento',
        4: 'Clase predeterminada',
        5: 'Certificaciones',
        6: 'Medidor Requerido',
        7: 'Id del Modelo'
      },
      onTransfer: 'En transferencia',
      period: ' | Cada dia | Cada {count} días',
      meterRequiredMessage: 'El medidor requerido requiere que el modelo sea Tipo de elemento de equipo. Puede cambiar esto en el campo Tipo de artículo de arriba.'
    },
    noItemMsg: 'No hay articulos asociados con el producto.',
    bulkGroup: {
      0: 'Número de artículos a granel',
      1: 'Código de barras de artículos a granel',
      2: 'Notas de artículos a granel'
    },
    panels: {
      equivalentsList: {
        title: 'Equivalentes de productos'
      },
      stockList: {
        title: 'Lista de existencias',
        itemFound: 'Ningún artículo encontrado | 1 artículo encontrado | {count} artículos encontrados'
      },
      itemList: {
        title: {
          0: 'Lista de articulos',
          1: 'Distribuciones'
        },
        tabs: {
          header: 'Artículo {name}',
          identification: {
            title: 'IDENTIFICACIÓN',
            labels: {
              0: 'Número de artículo',
              1: 'Codigo de barras',
              2: 'Número de serie',
              3: 'Escaneo Electrónico',
              4: 'Notas'
            }
          },
          assignment: {
            title: 'ASIGNACIÓN',
            labels: {
              0: 'Asignado a',
              1: 'Persona responsable',
              2: 'Uso personal',
              3: 'Fecha de regreso',
              4: 'Estado',
              5: 'Ubicación de la factura',
              6: 'En equipo',
              7: 'Clase',
              8: 'Metro'
            }
          },
          ownership: {
            title: 'PROPIEDAD',
            labels: {
              0: 'Propietario',
              1: 'Gerente',
              2: 'Departamento'
            }
          },
          purchase: {
            title: 'COMPRA',
            labels: {
              0: 'Fecha de compra',
              1: 'Costo de la compra',
              2: 'Orden de compra',
              3: 'Vencimiento de la garantía',
              4: 'Costo de remplazo'
            }
          },
          vehicle: {
            title: 'VEHÍCULO',
            labels: {
              0: 'Etiqueta de licencia',
              1: 'Estado',
              2: 'Vencimiento',
              3: 'Tarjeta de combustible',
              4: 'Medidor',
              5: 'Horas',
              6: 'VIN'
            }
          },
          leaseRental: {
            title: 'ARRENDAMIENTO/ALQUILER',
            lineActions: [
              {
                title: 'Agregar contrato',
                actionName: 'addContract'
              },
              {
                title: 'Editar contrato',
                actionName: 'editContract'
              }
            ]
          },
          customFields: {
            title: 'CAMPOS PERSONALIZADOS'
          },
          gpsMap: {
            title: 'MAPA'
          }
        }
      },
      cost: {
        title: 'Costo',
        labels: {
          0: 'MSRP',
          1: 'Costo de remplazo',
          2: 'Unidad de medida',
          3: 'No facture este modelo',
          4: 'Costo base del articulo Ea.',
          5: 'Vendedor preferido',
          6: 'Fecha'
        }
      },
      service: {
        title: 'SERVICIO',
        servicePlanEventDetail: 'Detalle del evento del plan de servicio',
        selectServieEvent: 'Seleccionar evento(s) de servicio',
        eventRecurrence: 'Periodo de Eventos',
        recurrence: {
          type: {
            title: 'Tipo de Periodos',
            byDate: 'Por fecha',
            byMeter: 'Por metro',
            byHours: 'Por Horas',
            byTurns: 'Por turnos',
            occursFirst: 'lo que ocurra primero'
          },
          pattern: {
            title: 'Patrón de Periodos',
            daily: 'A diario',
            weekly: 'Semanalmente',
            monthly: 'Mensual',
            yearly: 'Anual',
            weekday: 'día laborable',
            every: 'Cada',
            days: 'día(s)',
            recurEvery: 'Se repite cada',
            weeksOn: 'semanas en',
            day: 'Día',
            ofEvery: 'de cada',
            months: 'mese(s)',
            the: 'El',
            of: 'de'
          },
          rangeOfRecurrence: {
            title: 'Rango de Periodo',
            start: 'Comienzo',
            noEndDate: 'Sin fecha de finalización',
            endAfter: 'Fin después de',
            occurrence: 'ocurrencias',
            endBy: 'terminar a las'
          },
          odomter: {
            title: 'Odómetro / Metraje Periodo',
            startAt: 'Empieza en ...',
            repeatEvery: 'Repite cada ...',
            tooltip: 'La recurrencia del odómetro/medidor requiere que el modelo sea el tipo de elemento de equipo. Puede cambiar esto en el Detalle del modelo en Tipo de artículo.'
          },
          hours: {
            title: 'Horas',
            startAt: 'Empieza en ...',
            repeatEvery: 'Repite cada ...',
            tooltip: 'Sólo disponible para modelos tipo Equipo.'
          },
          usageRecurrence: {
            title: 'Periodo de uso',
            serviceAfterEvery: 'Servicio después de cada ...',
            assignments: 'asignaciones'
          },
          daysOfWeek: [
            {
              name: 'domingo',
              value: CONST.SUNDAY
            },
            {
              name: 'lunes',
              value: CONST.MONDAY
            },
            {
              name: 'martes',
              value: CONST.TUESDAY
            },
            {
              name: 'miércoles',
              value: CONST.WEDNESDAY
            },
            {
              name: 'jueves',
              value: CONST.THURSDAY
            },
            {
              name: 'viernes',
              value: CONST.FRIDAY
            },
            {
              name: 'sábado',
              value: CONST.SATURDAY
            },
            {
              name: 'día',
              value: CONST.ALLDAYS
            },
            {
              name: 'día laborable',
              value: CONST.WEEKDAY
            },
            {
              name: 'día de fin de semana',
              value: CONST.WEEKENDDAY
            }
          ],
          weekNumber: [
            {
              name: 'primer',
              value: 1
            },
            {
              name: 'segundo',
              value: 2
            },
            {
              name: 'tercero',
              value: 3
            },
            {
              name: 'cuarto',
              value: 4
            },
            {
              name: 'Último',
              value: 5
            }
          ],
          monthOfYear: [
            {
              name: 'enero',
              value: 1
            },
            {
              name: 'febrero',
              value: 2
            },
            {
              name: 'marzo',
              value: 3
            },
            {
              name: 'abril',
              value: 4
            },
            {
              name: 'Mayo',
              value: 5
            },
            {
              name: 'junio',
              value: 6
            },
            {
              name: 'julio',
              value: 7
            },
            {
              name: 'agosto',
              value: 8
            },
            {
              name: 'septiembre',
              value: 9
            },
            {
              name: 'octubre',
              value: 10
            },
            {
              name: 'noviembre',
              value: 11
            },
            {
              name: 'diciembre',
              value: 12
            }
          ],
          warning: {
            title: 'Periodo de citas',
            message: 'La propiedad "PatternDaysOfWeek" no se puede establecer en "Ninguno" cuando la propiedad "PatternFrequency" se establece en "Weekly"'
          }
        },
        panels: {
          0: 'Planes de servicio',
          1: 'Órdenes de trabajo abierto',
          2: 'Historial de órdenes de trabajo',
          3: 'Partes para ordenes',
          4: 'Especificaciones de calibración'
        },
        servicePlans: {
          tableHeader: {
            desktop: [
              {
                label: '',
                key: 'ServiceEventId',
                type: 'check'
              },
              {
                label: 'Nombre',
                key: 'Description',
                sortable: true
              },
              {
                label: 'Periódo',
                key: 'IsRecurring',
                type: 'recurring',
                sortable: true
              },
              {
                label: 'Método del Periodo',
                key: 'RecurrenceMethod',
                type: 'recurringMethod',
                sortable: true
              },
              {
                label: 'Activar',
                key: 'TriggerOn',
                type: 'recurringTriggerOn',
                sortable: true
              },
              {
                label: 'Notas',
                key: 'Notes',
                sortable: true
              }
            ],
            mobile: [
              {
                label: '',
                key: 'ServiceEventId',
                type: 'check'
              },
              {
                label: 'Nombre',
                key: 'Description',
                sortable: true
              },
              {
                label: 'Info',
                data: [{
                  label: 'Periódico',
                  key: 'IsRecurring',
                  type: 'recurring',
                  sortable: true
                },
                {
                  label: 'Método del Periodo',
                  key: 'RecurrenceMethod',
                  type: 'recurringMethod',
                  sortable: true
                },
                {
                  label: 'Activar',
                  key: 'TriggerOn',
                  type: 'recurringTriggerOn',
                  sortable: true
                },
                {
                  label: 'Nota',
                  key: 'Notes',
                  sortable: true
                }]
              }
            ]
          }
        },
        calibrationDlg: {
          title: 'Nueva Calibración',
          labels: {
            description: 'Descripción',
            standard: 'Estándar',
            min: 'Mínimo',
            max: 'Máximo',
            unit: 'Unidad'
          }
        },
        calibrationSpecDeleteDlg: {
          title: 'Borrar...',
          msg: '¿Está seguro de que desea eliminar la especificación de calibración?:'
        },
        servicePlanLineActions: [
          {
            title: 'Agregar',
            actionName: 'addServicePlan',
            isNoSelection: true,
            isMultiple: true
          },
          {
            title: 'Agregar evento desde el modelo',
            actionName: 'addEventFromModel',
            isNoSelection: true,
            isShown: function (isItem) {
              return isItem
            }
          },
          {
            title: 'Editar',
            actionName: 'editServicePlan',
            isMultiple: false
          },
          {
            title: 'Borrar',
            actionName: 'deleteServicePlan',
            isMultiple: true
          }
        ],
        repairPartsLineActions: [
          {
            title: 'Agregar',
            actionName: 'addRepairParts'
          },
          {
            title: 'Borrar',
            actionName: 'deleteRepairParts'
          }
        ],
        calibrationSpecsLineActions: [
          {
            title: 'Agregar',
            actionName: 'addCalibrationSpecs',
            requiresSelection: false,
            allowsMultiSelect: true
          },
          {
            title: 'Borrar',
            actionName: 'deleteCalibrationSpecs',
            requiresSelection: true,
            allowsMultiSelect: true
          }
        ],
        serviceEvents: {
          0: {
            planDetails: {
              title: 'Detalles del plan',
              labels: {
                0: 'Periodo',
                1: 'Comentarios'
              }
            }
          },
          1: {
            tasks: {
              title: 'Tareas',
              labels: {
                0: 'Tipo',
                1: 'Descripción',
                2: 'Horas estimadas'
              }
            }
          },
          2: 'Instrucciones',
          3: {
            tools: {
              title: 'Herramientas',
              labels: {
                0: 'Descripción',
                1: 'Fabricar',
                2: 'Modelo',
                3: 'Generico',
                4: 'Cant'
              }
            }
          },
          4: {
            parts: {
              title: 'Partes',
              labels: {
                0: 'Descripción',
                1: 'Artículo',
                2: 'Centro de costos',
                3: 'Código de costo',
                4: 'Cant'
              }
            }
          },
          5: {
            charges: {
              title: 'Cargos',
              labels: {
                0: 'Tipo',
                1: 'Artículo',
                2: 'Descripción',
                3: 'Cant',
                4: 'Costo',
                5: 'Unidades'
              }
            }
          }
        },
        openWorkOrders: {
          0: 'Número',
          1: 'Creado',
          2: 'Prioridad',
          3: 'Estado'
        },
        workOrderHistory: {
          0: 'Orden de trabajo',
          1: 'Creado',
          2: 'Completado',
          3: 'Cargos',
          4: 'Servicio solicitado',
          5: 'Nota de solicitud de servicio'
        },
        repairParts: {
          tableHeader: {
            desktop: [
              {
                label: '',
                type: 'check',
                key: 'RecordId'
              },
              {
                label: 'Descripción',
                key: 'Description',
                sortable: true
              },
              {
                label: 'Número',
                key: 'Number',
                sortable: true
              },
              {
                label: 'Fabricante',
                key: 'Manufacturer',
                sortable: true
              },
              {
                label: 'Modelo',
                key: 'Model',
                sortable: true
              },
              {
                label: 'Requerido',
                key: 'Required',
                sortable: true
              },
              {
                label: 'Costo CU.',
                key: 'Cost Ea.',
                sortable: true
              }
            ],
            mobile: [
              {
                label: '',
                type: 'check',
                key: 'RecordId'
              },
              {
                label: 'Descripción',
                key: 'Description',
                sortable: true
              },
              {
                label: 'Info',
                data: [
                  {
                    label: 'Número',
                    key: 'Number',
                    sortable: true
                  },
                  {
                    label: 'Fabricante',
                    key: 'Manufacturer',
                    sortable: true
                  },
                  {
                    label: 'Modelo',
                    key: 'Model',
                    sortable: true
                  },
                  {
                    label: 'Requerido',
                    key: 'Required',
                    sortable: true
                  },
                  {
                    label: 'Costo Cu.',
                    key: 'Cost Ea.',
                    sortable: true
                  }
                ]
              }
            ]
          }
        },
        calibrationSpecs: {
          tableHeader: {
            desktop: [
              {
                label: '',
                type: 'check',
                key: 'CalibrationSpecId'
              },
              {
                label: 'Descripción',
                key: 'Description',
                sortable: true
              },
              {
                label: 'Mínimo',
                key: 'StdMin',
                sortable: true
              },
              {
                label: 'Máx',
                key: 'StdMax',
                sortable: true
              },
              {
                label: 'Unidad',
                key: 'Unit',
                sortable: true
              }
            ],
            mobile: [
              {
                label: '',
                type: 'check',
                key: 'CalibrationSpecId'
              },
              {
                label: 'Descripción',
                key: 'Description',
                sortable: true
              },
              {
                label: 'Info',
                data: [
                  {
                    label: 'Mínimo',
                    key: 'StdMin',
                    sortable: true
                  },
                  {
                    label: 'Máx',
                    key: 'StdMax',
                    sortable: true
                  },
                  {
                    label: 'Unidad',
                    key: 'Unit',
                    sortable: true
                  }
                ]
              }
            ]
          }
        }
      }
    }
  },
  meterDialog: {
    title: 'Configuración de requisitos del medidor',
    labels: {
      0: 'Requiere lectura del medidor cada',
      1: 'Requerir lectura del medidor en la transferencia'
    },
    days: ' | día | dias'
  },
  attachment: {
    title: 'Archivos adjuntos',
    labels: {
      0: 'Descripción',
      1: 'Última actualización',
      2: 'Actualizado por',
      3: 'Tipo',
      4: 'Archivo',
      5: 'Dirección web (URL)',
      6: 'Localización',
      7: 'Navegar',
      8: 'Resumen',
      9: 'Nombre nuevo'
    }
  },
  adjustForm: {
    title: 'Ajuste de inventario',
    labels: {
      0: 'Artículo:',
      1: 'Cantidad actual',
      2: 'Cantidad ajustada',
      3: 'Razón del ajuste',
      4: 'Recuento completo tomado',
      5: 'Documento'
    }
  },
  menu: {
    modelRecord: [
      {
        title: 'Ver Detalle',
        isMultiple: false,
        isSingle: true,
        isTool: true,
        isMaterial: true,
        actionName: 'viewDetail'
      },
      {
        title: 'Copiar producto',
        isMultiple: false,
        isSingle: true,
        isTool: true,
        isMaterial: true,
        actionName: 'copyModel'
      },
      {
        title: 'Combinar productos',
        isMultiple: true,
        isSingle: false,
        isTool: true,
        isMaterial: true,
        actionName: 'mergeModel'
      },
      {
        title: 'Eliminar producto',
        isMultiple: false,
        isSingle: true,
        isTool: true,
        isMaterial: true,
        actionName: 'removeModel'
      },
      {
        title: 'Fusionar duplicados',
        isMultiple: false,
        isSingle: true,
        isTool: true,
        isMaterial: true,
        actionName: 'mergeDuplicates'
      }
    ],
    equivalentsList: [
      {
        title: 'Añadir Equivalente'
      }
    ],
    stockList: [
      {
        title: 'Añadir información de abastecimiento',
        actionName: 'addStock',
        isNoSelection: true,
        isSingle: false,
        isMultiple: false,
        isTool: false,
        isQuantityType: true,
        isMaterial: true
      }
    ],
    toolList: [
      {
        title: 'Añadir artículo',
        actionName: 'addItem',
        isTool: true,
        isQuantityType: true,
        isMaterial: true,
        requiresSelection: false,
        allowsMultiSelect: true
      },
      {
        title: 'Editar elemento',
        actionName: 'editItem',
        isTool: true,
        isQuantityType: true,
        isMaterial: true,
        requiresSelection: true,
        allowsMultiSelect: false
      }
    ]
  },
  subActionHeaderForCatalogHomeAndResult: {
    0: {
      title: 'Inicio',
      icon: 'fas fa-home'
    },
    1: {
      title: 'Agregar modelo',
      icon: 'fas fa-plus'
    }
  },
  subActionHeaderForCatalogDetail: {
    0: {
      title: 'Regresar',
      icon: 'fas fa-undo'
    },
    1: {
      title: 'Archivos adjuntos',
      icon: 'fas fa-paperclip'
    },
    2: {
      title: 'Añadir artículo',
      icon: 'fas fa-plus'
    },
    3: {
      title: 'Añadir stock',
      icon: 'fas fa-plus'
    }
  },
  mergeModels: {
    warningTitle: 'No se pueden fusionar modelos de herramientas únicos.',
    warningMessage: 'No se pueden fusionar los modelos de herramientas con seguimiento de cantidad. \n Restrinja su selección solo a modelos de herramientas únicos.'
  },
  mergeDuplicates: {
    quantityItemsOnly: 'Sólo se pueden fusionar artículos duplicados para modelos de cantidad',
    noDuplicates: 'MODEL no tiene registros duplicados para fusionar.',
    duplicatesExist: 'MODEL tiene registros de inventario duplicados con diferentes números de artículo y/o códigos de barras. Seleccione el elemento que debe permanecer después de fusionar.',
    confirmMerge: 'ITEMNUMBER / BARCODE será el número y el código de barras restantes después de fusionar',
    openBillingWarning: 'Fusionar registros de inventario para MODEL.\nTodos los flujos de facturación abiertos en artículos combinados se cerrarán mediante este proceso.\nEspere varios minutos para que los resultados aparezcan en el Explorador de herramientas y en la herramienta de registro de modelo. Lista.\n¿Continuar con Fusionar?'
  },
  dlgDeleteCatalogModelConfirm: {
    activeTitle: 'Eliminar modelo activo?',
    title: 'Borrar...',
    activeMessage: 'Este modelo tiene registros activos asociados. Estás seguro de que quieres eliminarlo ?',
    message: 'Estás segura de que quieres eliminar el modelo: '
  },
  catlogDragPaste: {
    pastedragText: 'o arrastrar y soltar, copiar y pegar archivos',
    btnText: 'Subir nueva imagen',
    dropText: 'Caer aquí'
  }
}

export default catalog

import message from './message.js'
import routes from './routes.js'
import platformNavigationRoutes from './platformNavigationRoutes.js'
import buttons from './buttonText.js'
import dashboard from './dashboard.js'
import authorization from './authorization.js'
import metric from './metricTable.js'
import catalog from './catalog.js'
import equivalentsLists from './equivalentsTable.js'
import stockLists from './stocklistTable.js'
import paging from './pagination.js'
import toolLists from './toollistTable.js'
import requisition from './requisition.js'
import scheduling from './scheduling.js'
import reqbyReq from './reqbyReq.js'
import reservationsByReservation from './reservationsByReservation.js'
import searchReturns from './searchReturns.js'
import report from './report.js'
import customReports from './customReports.js'
import customDashboards from './customDashboard.js'
import folderReports from './folderReports.js'
import scheduledReports from './scheduledReports.js'
import admin from './admin.js'
import bleTable from './bleTable.js'
import bleTag from './bleTag.js'
import numberHistory from './numberHistory.js'
import descriptions from './descriptions.js'
import categories from './categories.js'
import catalogProducts from './catalogProducts.js'
import retHistory from './retHistory.js'
import certifications from './certifications.js'
import classes from './classes.js'
import contractors from './contractors.js'
import costCenters from './costCenters.js'
import costCodes from './costCodes.js'
import customers from './customers.js'
import departments from './departments.js'
import employeeCert from './employeeCert.js'
import employees from './employees.js'
import employeeTypes from './employeeTypes.js'
import locations from './locations.js'
import manufacturers from './manufacturers.js'
import numberBlocks from './numberBlocks.js'
import serviceClasses from './serviceClasses.js'
import shippingMethods from './shippingMethods.js'
import statusCodes from './statusCodes.js'
import unitsOfMeasures from './unitsOfMeasures.js'
import vendors from './vendors.js'
import costLists from './costLists.js'
import procore from './procore.js'
import userRoles from './userRoles.js'
import appLists from './appLists.js'
import billing from './billing.js'
import rateSheets from './rateSheets.js'
import rateSheetView from './rateSheetView.js'
import calcMethods from './calculationMethods.js'
import billingBrowser from './billingBrowser.js'
import calcStatus from './calcStatus.js'
import postData from './postData.js'
import scanItems from './scanItems.js'
import dataMergers from './dataMergers.js'
import exportTemplates from './exportTemplates.js'
import equipmentUsageLog from './equipmentUsageLog.js'
import tickets from './tickets.js'
import transferTicket from './transferTicket.js'
import pickTicket from './pickTicket.js'
import transfer from './transfer.js'
import toolTransferItems from './toolTransferItems.js'
import materialTransferItems from './materialTransferItems.js'
import pickLists from './pickLists.js'
import pickedItems from './pickedItems.js'
import toolCategoryItems from './toolCategoryItems.js'
import materialCategoryItems from './materialCategoryItems.js'
import service from './service.js'
import workOrder from './workOrder.js'
import workOrderItem from './workOrderItem.js'
import workOrderTasks from './workOrderTasks.js'
import workPerformed from './workPerformed.js'
import workRequiredTools from './workRequiredTools.js'
import workRequiredParts from './workRequiredParts.js'
import workAssignment from './workAssignment.js'
import workOrderCharges from './workOrderCharges.js'
import companyCalendar from './companyCalendar.js'
import toolPickItems from './toolPickItems.js'
import materialPickItems from './materialPickItems.js'
import materialInventory from './materialInventory.js'
import invoiceBrowserTable from './invoiceBrowserTable.js'
import invoiceBrowserGenerate from './invoiceBrowserGenerate.js'
import help from './help.js'
import configureUserRoles from './configureUserRoles.js'
import configureUserPlatformRoles from './configureUserPlatformRoles.js'
import dataLoader from './dataLoader.js'
import meter from './meterTable.js'
import countTicketsTable from './countTicketsTable.js'
import reinstate from './reinstate.js'
import listSearchBrowser from './listSearchBrowser.js'
import integration from './integration.js'
import scanModelTableHeader from './scanModelTableHeader.js'
import countTicketTabCountSummaryTable from './countTicketTabCountSummaryTable.js'
import countTicketTabCountedItemsTable from './countTicketTabCountedItemsTable.js'
import inventoryAdjustmentsTable from './inventoryAdjustmentsTable.js'
import adjustmentDetailTable from './adjustmentDetailTable.js'
import validations from './validations.js'
import template from './template.js'
import templateHolder from './templateHolder.js'
import serviceSchedule from './serviceSchedule.js'
import expressCheckIn from './expressCheckIn.js'
import purchasing from './purchasing.js'
import expandedToolItems from './expandedToolItems.js'
import integrationSettings from './integrationSettings.js'
import templatePickItems from './templatePickItems.js'
import retireTools from './retireTools.js'
import retiredTools from './retiredTools.js'
import recycleBin from './recycleBin.js'
import itemBrowser from './itemBrowser.js'
import taskBrowser from './taskBrowser.js'
import recurrenceSchedule from './recurrenceSchedule.js'
import lowLevelBrowser from './lowLevelBrowser.js'
import customFields from './customFields.js'
import kits from './kits.js'
import addItems from './addItems.js'
import reservations from './reservations.js'
import map from './map.js'
import twGridColumnsDisplay from './twGridColumnsDisplay'
import listVisibility from './ListVisibility.js'
import tasksCategories from './tasksCategories.js'
import { users, usersTable } from './users.js'
import fuelTransactionBrowser from './fuelTransactionBrowser.js'
import fuelTransactionTable from './fuelTransactionTable.js'
import fuelCards from './fuelCards.js'
import fuelEmployees from './fuelEmployees.js'
import fuelEmployeesTable from './fuelEmployeesTable.js'
import tasksPriorities from './tasksPriorities.js'
import tasksStatuses from './tasksStatuses.js'
import collaborate from './collaborate.js'
import fuelTransactionImportTable from './fuelTransactionImportTable.js'
import documentCentral from '@/locales/en/documentCentral'
import rateSheetViewForMaterials from './rateSheetViewForMaterials.js'
import locationMap from './locationMap.js'
import safetyPlan from '@/locales/en/safetyPlan.js'
import dashboardsRoutes from './dashboardsRoutes.js'
import EHSNavigationRoutes from './EHSNavigationRoutes.js'
import EHSDashboardsRoutes from './EHSDashboardsRoutes.js'
import safetyPlanGeneralInfo from './safetyPlanGeneralInfo.js'
import safetyPlanInspectionDetail from './safetyPlanInspectionDetail.js'
import workflowManagement from './workflowManagement.js'
import workflowBuilder from './WorkflowBuilder.js'
import workflow from './Workflow.js'
import workflowBrowser from './WorkflowBrowser.js'
import frequency from './frequency.js'
import safetyPlanScanDetail from './safetyPlanScanDetail.js'
import safetyPlanFormDetail from './safetyPlanFormDetail.js'
import safetyPlanContractorDetail from './safetyPlanContractorDetail.js'
import schedulingBrowser from './schedulingBrowser.js'
import workflowApprovals from './workflowApprovals.js'
import platformPermissionsTooltip from './platformPermissionsTooltip.js'
import requisitionBrowser from './requisitionBrowser.js'
import requisitionBrowserView from './requisitionBrowserView.js'
import WFMNavigationRoutes from './WFMNavigationRoutes.js'
import PickedItemsList from './PickedItemsList.js'
import jobStocks from './jobStocks.js'
const en = {
  menu: {
    en: 'English',
    es: 'Spanish'
  },
  message,
  routes,
  platformNavigationRoutes,
  buttons,
  dashboard,
  authorization,
  metric,
  catalog,
  equivalentsLists,
  stockLists,
  paging,
  toolLists,
  requisition,
  scheduling,
  reqbyReq,
  reservationsByReservation,
  searchReturns,
  report,
  customReports,
  folderReports,
  scheduledReports,
  admin,
  bleTable,
  bleTag,
  numberHistory,
  descriptions,
  categories,
  catalogProducts,
  retHistory,
  certifications,
  classes,
  contractors,
  costCenters,
  costCodes,
  customers,
  departments,
  employeeCert,
  employees,
  employeeTypes,
  locations,
  manufacturers,
  numberBlocks,
  serviceClasses,
  shippingMethods,
  statusCodes,
  unitsOfMeasures,
  vendors,
  costLists,
  procore,
  userRoles,
  appLists,
  billing,
  rateSheets,
  rateSheetView,
  calcMethods,
  billingBrowser,
  calcStatus,
  postData,
  scanItems,
  dataMergers,
  exportTemplates,
  equipmentUsageLog,
  tickets,
  transferTicket,
  pickTicket,
  transfer,
  toolTransferItems,
  materialTransferItems,
  pickLists,
  pickedItems,
  toolCategoryItems,
  toolPickItems,
  materialPickItems,
  materialInventory,
  materialCategoryItems,
  service,
  workOrder,
  workOrderItem,
  workOrderTasks,
  workPerformed,
  workRequiredTools,
  workRequiredParts,
  workAssignment,
  workOrderCharges,
  companyCalendar,
  invoiceBrowserTable,
  invoiceBrowserGenerate,
  help,
  meter,
  configureUserRoles,
  configureUserPlatformRoles,
  dataLoader,
  countTicketsTable,
  template,
  templateHolder,
  reinstate,
  listSearchBrowser,
  integration,
  scanModelTableHeader,
  countTicketTabCountSummaryTable,
  countTicketTabCountedItemsTable,
  inventoryAdjustmentsTable,
  adjustmentDetailTable,
  validations,
  integrationSettings,
  expressCheckIn,
  serviceSchedule,
  retireTools,
  retiredTools,
  templatePickItems,
  purchasing,
  expandedToolItems,
  recycleBin,
  itemBrowser,
  taskBrowser,
  recurrenceSchedule,
  lowLevelBrowser,
  customFields,
  kits,
  addItems,
  reservations,
  map,
  twGridColumnsDisplay,
  listVisibility,
  customDashboards,
  users,
  usersTable,
  fuelTransactionBrowser,
  fuelTransactionTable,
  fuelCards,
  fuelEmployees,
  fuelEmployeesTable,
  tasksCategories,
  tasksPriorities,
  tasksStatuses,
  collaborate,
  fuelTransactionImportTable,
  documentCentral,
  rateSheetViewForMaterials,
  locationMap,
  safetyPlan,
  dashboardsRoutes,
  EHSNavigationRoutes,
  EHSDashboardsRoutes,
  safetyPlanGeneralInfo,
  safetyPlanInspectionDetail,
  workflowManagement,
  workflowBuilder,
  workflow,
  frequency,
  safetyPlanFormDetail,
  safetyPlanScanDetail,
  workflowBrowser,
  safetyPlanContractorDetail,
  schedulingBrowser,
  workflowApprovals,
  platformPermissionsTooltip,
  requisitionBrowser,
  requisitionBrowserView,
  WFMNavigationRoutes,
  PickedItemsList,
  jobStocks
}

export default en

const authorization = {
  email: 'Correo electrónico de usuario de TW',
  emailAlign: 'Correo electrónico de usuario de Align',
  pwd: 'Contraseña Usuario TW',
  pwdAlign: 'Contraseña Usuario Align',
  msg: 'Usuario o contraseña inválidos.',
  title: 'Bienvenido a ToolWatch',
  titleTooLops: 'Bienvenido a ToolOps',
  titleAlign: 'Bienvenido a AlignOps',
  login: 'Iniciar sesión',
  next: 'Siguiente',
  rememberMe: 'Recuérdar Usurio'
}

export default authorization

const pickTicket = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'PickTicketId',
        type: 'check'
      },
      {
        label: 'Número',
        type: Number,
        key: 'Number',
        sortable: true
      },
      {
        label: 'Colaborar',
        type: Number,
        key: 'CollaborateCount',
        sortable: true,
        flag: 'showCollaborateExpansion'
      },
      {
        label: 'Creado',
        type: Date,
        key: 'CreatedOn',
        sortable: true,
        filtertype: 'DateTime'
      },
      {
        label: 'Estado',
        type: String,
        key: 'State',
        sortable: true
      },
      {
        label: 'Estatus',
        type: String,
        key: 'Status',
        sortable: true
      },
      {
        label: 'Fuente',
        type: String,
        key: 'OriginationType',
        sortable: true
      },
      {
        label: 'Elegido desde',
        key: 'PickFrom',
        sortable: true
      },
      {
        label: 'Elegir para',
        type: String,
        key: 'PickFor',
        sortable: true
      },
      {
        label: 'Solicitado por',
        key: 'RequestedBy',
        sortable: true
      },
      {
        label: 'Vencimiento',
        type: Date,
        key: 'PickDueDate',
        sortable: true,
        filtertype: 'DateTime'
      },
      {
        label: 'Asignado a',
        type: String,
        key: 'AssignedTo',
        sortable: true
      },
      {
        label: 'Elegido por',
        type: String,
        key: 'PickedBy',
        sortable: true
      },
      {
        label: 'Desde Código de Costo',
        type: String,
        key: 'FromCostCodeDescription',
        sortable: true
      },
      {
        label: 'A Código de costo',
        type: String,
        key: 'ToCostCodeDescription',
        sortable: true
      },
      {
        label: 'Recuento de elementos',
        type: Number,
        key: 'ItemCount',
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'PickTicketId',
        type: 'check'
      },
      {
        label: 'Número',
        type: Number,
        key: 'Number',
        sortable: true
      },
      {
        label: 'Colaborar',
        type: Number,
        key: 'CollaborateCount',
        flag: 'showCollaborateExpansion'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Creado',
            type: Date,
            key: 'CreatedOn',
            sortable: true
          },
          {
            label: 'Estado',
            type: String,
            key: 'State',
            sortable: true
          },
          {
            label: 'Estatus',
            type: String,
            key: 'Status',
            sortable: true
          },
          {
            label: 'Fuente',
            type: String,
            key: 'OriginationType',
            sortable: true
          },
          {
            label: 'Elegido de',
            key: 'PickFrom',
            sortable: true
          },
          {
            label: 'Elegir para',
            type: String,
            key: 'PickFor',
            sortable: true
          },
          {
            label: 'Solicitado por',
            key: 'RequestedBy',
            sortable: true
          },
          {
            label: 'Vencimiento',
            type: Date,
            key: 'PickDueDate',
            sortable: true
          },
          {
            label: 'Asignado a',
            type: String,
            key: 'AssignedTo',
            sortable: true
          },
          {
            label: 'Elegido por',
            type: String,
            key: 'PickedBy',
            sortable: true
          },
          {
            label: 'Desde Código de Costo',
            type: String,
            key: 'FromCostCodeDescription',
            sortable: true
          },
          {
            label: 'A Código de costo',
            type: String,
            key: 'ToCostCodeDescription',
            sortable: true
          },
          {
            label: 'Recuento de elementos',
            type: Number,
            key: 'ItemCount',
            sortable: true
          }
        ]
      }
    ]
  }
}

export default pickTicket
